











































import ODataMixin from '@/mixins/ODataMixin'
import SourceDataHistoryDialog from '@/areas/sourceData/components/SourceDataHistoryDialog.vue'
import SourceDataTable from '@/areas/sourceData/components/SourceDataTable.vue'
import FilterModel from '@/models/FilterModel'
import IProcessRun from '../models/IProcessRun'

export default ODataMixin.extend({
  components: {
    SourceDataHistoryDialog,
    SourceDataTable
  },
  data() {
    return {
      processRun: null as IProcessRun,
      sourceDataHistoryRecord: null,
      filters: [
        new FilterModel('Processed', {
          filter: 'errored eq false'
        }),
        new FilterModel('New', {
          filter: 'inserted eq true and errored eq false'
        }),
        new FilterModel('Updated', {
          filter: 'updated eq true and errored eq false'
        }),
        new FilterModel('Errored', {
          filter: 'errored eq true'
        })
      ] as FilterModel[]
    }
  },
  computed: {
    title(): string {
      return `${this.processRun?.process?.name}`
    },
    url(): string {
      return `ProcessRuns(${this.processRunId})/SourceData()`
    },
    processId(): number {
      return +this.$route.query.processId
    },
    processRunId(): number {
      return +this.$route.params.id
    },
    filter(): string {
      if (this.processId) {
        return `processId eq ${this.processId}`
      }
      return null
    }
  },
  methods: {
    async getProcessRun() {
      this.processRun = await this.getSingle<IProcessRun>(
        `ProcessRuns(${this.processRunId})?$expand=process($select=name)&$select=startTime,endTime,status,process`
      )
    }
  },
  created() {
    this.getProcessRun()
  }
})
